/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import type { ErrorEvent, TransactionEvent } from '@sentry/types';
import { BrowserTracing } from '@sentry/tracing';
import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
} from '@sentry/integrations';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { isRemoteEnvironment } from 'utils/constants';
import { sanitiser } from 'utils/sanitiser';

if (isRemoteEnvironment) {
  try {
    const eventSanitiser = (event: ErrorEvent | TransactionEvent) => {
      const thirdPartyRequestUrls = [
        'grsm.', // partnerstack event
        'google.',
        'segment.', // segment event
        'vitally.',
        'fullstory.',
        'doubleclick.',
        'partnerlinks.',
      ];
      const isThirdPartyEvent = thirdPartyRequestUrls.some((requestUrl) =>
        event.request?.url?.includes(requestUrl)
      );
      // skip events originated from third party scripts
      if (isThirdPartyEvent) return null;

      const { breadcrumbs, extra } = event;
      // sanitize breadcrumb data
      event.breadcrumbs = breadcrumbs?.map((breadcrumb) => {
        breadcrumb.data = sanitiser(breadcrumb.data);
        return breadcrumb;
      });

      // sanitize any extra data
      event.extra = sanitiser(extra);

      return event;
    };

    // initialize sentry
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      integrations: [
        new ExtraErrorDataIntegration({
          depth: 5,
        }),
        new CaptureConsoleIntegration({
          levels: ['error'],
        }),
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],
      tracesSampleRate: 1.0,
      attachStacktrace: true,
      /**
       * Controls if potentially sensitive data should be sent to Sentry by default.
       * Note that this only applies to data that the SDK is sending by default
       * but not data that was explicitly set (e.g. by calling `Sentry.setUser()`).
       *
       * Should always be `false`
       */
      sendDefaultPii: false,
      beforeSend(event) {
        /**
         * sanitize before error and message events are sent
         */
        const sanitizedEvent = eventSanitiser(event);
        return sanitizedEvent;
      },
      beforeSendTransaction(event) {
        /**
         * sanitize before transactions events are sent (i.e general monitoring or tracking events)
         * https://docs.sentry.io/product/performance/transaction-summary/
         */
        const sanitizedEvent = eventSanitiser(event);
        return sanitizedEvent;
      },
      ignoreErrors: [/ResizeObserver/gi],
    });
    Sentry.setTags({
      code_owner: 'vq_client', // Follow general tagging convention
      application: 'signup_frontend',
    });
  } catch (e) {
    /* do nothing */
  }
}
