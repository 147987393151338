import { SignupData } from 'context/types';

export interface RawPayload extends Omit<SignupData, 'country'> {
  country?: string;
}

type ExcludedAttributes =
  | 'password'
  | 'amazonOauthAccessToken'
  | 'mwsAuthToken'
  | 'spapiOauthCode'
  | 'access_token'
  | 'secret';

export type BasePayload = Omit<RawPayload, ExcludedAttributes>;

/**
 * A catalogues of Segment Event Keys
 *  - Camel case event keys are already in use and cannot be changed without updating Metabase queries
 */
export enum Event {
  BUTTON_CLICKED = 'button clicked',
  SUBMIT_SUCCESS = 'submit success',
  ACCOUNT_CREATED = 'account_created',
  CONTINGENCY_WAITING_LIST_SUBSCRIPTION = 'contingency_signup_waiting_list_subscription',
  MOBILE_WAITING_LIST_SUBSCRIPTION = 'mobile_signup_waiting_list_subscription',
  ROW_WAITING_LIST_SUBSCRIPTION = 'ROW_signup_waiting_list_subscription',
}

/**
 * A catalogues of locations available for the Segment Track events
 */
export enum Location {
  LOGIN_HOMEPAGE = 'login homepage',
  SIGNUP_NAVBAR = 'signup navbar',
  SIGNUP_HOMEPAGE = 'signup homepage',
  SIGNUP_COMPANY_DETAILS = 'signup company details',
  AMAZON_SSO_SIGNUP = 'amazon sso signup',
}

/**
 * A catalogues of actions available for the Segment Track events
 */
export enum Action {
  LOGIN = 'login',
  SIGNUP = 'signup',
  CONNECT_STORE = 'connect store',
  CONTINUE_WITH_EMAIL = 'continue with email',
  CONTINUE_WITH_AMAZON = 'continue with amazon',
  AMAZON_SSO_DATA_PULLED = 'amazon sso data pulled',
  LOGIN_WITH_EMAIL = 'login with email',
  LOGIN_WITH_AMAZON = 'login with amazon',
  ENTER_ADDRESS_MANUALLY = 'enter address manually',
}

/**
 * A catalogues of targets available for the Segment Track events
 */
export enum Target {
  AMAZON_SSO_LOGIN = 'amazon sso login',
  VEEQO_APP = 'veeqo application',
}

/**
 * A catalogues of waiting lists available for the Segment Track events
 */
export enum WaitingList {
  MOBILE = 'mobile',
  ROW = 'ROW',
  CONTINGENCY = 'contingency',
}

/**
 * Custom properties available for the Segment Track events
 */
export interface TrackProperties extends BasePayload {
  location?: Location;
  action?: Action;
  target?: Target | Location;
  origin?: Location;
  ssoData?: {
    login: string | undefined;
    email: string | undefined;
    country: string | undefined;
    amazonOauthUid: string | undefined;
  };
}

/**
 * Integrations available for the Segment Identify events
 */
export type IdentifyIntegrations = {
  salesforce?: boolean;
};

/**
 * Base traits available for the Segment Identify events
 */
export interface IdentifyTraitsBase extends Omit<BasePayload, 'companyName' | 'marketingConsent'> {
  company?: {
    name: string | undefined;
  };
  signup_waiting_list?: WaitingList;
  get_address?: boolean;
  marketingConsent?: boolean;
  marketingConsentDate?: string;
  password?: ''; // keep empty!
}

export type IdentifyTraitsWithUTMs = IdentifyTraitsBase & UTMDataType;
export type IdentifyTraitsWithUTMsAndPartner = IdentifyTraitsWithUTMs & PartnerData;
export type IdentifyTraits =
  | IdentifyTraitsBase
  | IdentifyTraitsWithUTMs
  | IdentifyTraitsWithUTMsAndPartner;

/**
 * UTM data as passed to Segment once extracted from the campaign cookies
 */
export type UTMDataType = {
  utm_1st_URL?: string;
  utm_1st_campaign?: string;
  utm_1st_content?: string;
  utm_1st_medium?: string;
  utm_1st_referrer?: string;
  utm_1st_source?: string;
  utm_1st_term?: string;
  utm_1st_campaignid?: string;
  utm_1st_click_date?: string;
  utm_1st_gclid?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_referrer?: string;
  utm_source?: string;
  utm_term?: string;
  utm_campaignid?: string;
  utm_click_date?: string;
  utm_gclid?: string;
  lead_type?: string;
  partner_key?: string;
  partner_email_address?: string;
  utm_last_page: string;
};

/**
 * Data passed to Partnerstack
 */
export type PartnerData = {
  lead_type: string;
  lead_source_origin: string;
  partner_key: string;
  partner_email_address: string;
};
